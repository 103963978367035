import axios, { AxiosInstance } from 'axios';

axios.defaults.withCredentials = true;
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ORION_API_ENDPOINT,
  headers: {
    'Content-type': 'application/json',
    'Api-key': process.env.REACT_APP_ORION_API_KEY
  },
});
