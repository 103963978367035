import { Box, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import LastUpdate from '../../assets/images/lastupdates.svg';
import TotalExperience from '../../assets/images/totalexperience.svg';
import Effort from '../../assets/images/mandaysofefforts.svg';
import UsedInProduct from '../../assets/images/usedinproducts.svg';
import ReadingTime from '../../assets/images/timetoread.svg';
import dateFormat from 'dateformat';

interface BannerInterface {
  date?: any;
  totalExperience?: string;
  manDaysOfEffort?: string;
  usedInProduct?: string;
  readingTime?: string;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#343434',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#343434',
    color: '#D1D6DD',
    fontSize: '.625rem',
  },
}));

const KbBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
  borderTop: '1px solid #ccc',
  padding: '0.75rem 0',
  margin: '1.5rem 0',
}));

const KbItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  span: {
    color: '#888888',
    fontWeight: '400',
    fontSize: '0.875rem',
  },
}));

const KbDot = styled(Box)(({ theme }) => ({
  width: '0.25rem',
  height: '0.25rem',
  borderRadius: '0.25rem',
  backgroundColor: '#B9B9B9',
}));
const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const formatDate = (date: string) => {
  const day = dateFormat(date, 'd'); // Get day without leading zero
  const dayWithSuffix = getOrdinalSuffix(parseInt(day, 10));
  const formattedDate = dateFormat(date, `mmmm, yyyy`);
  return `${dayWithSuffix} ${formattedDate}`;
};

export const Banner = ({ date, totalExperience, manDaysOfEffort, usedInProduct, readingTime }: BannerInterface) => {
  return (
    <>
      <KbBanner>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.75rem' }}>
          {date && (
            <KbItemContainer>
              <BootstrapTooltip title='Last Updated'>
                <img src={LastUpdate} alt='Last Updated' />
              </BootstrapTooltip>

              <span>{formatDate(date)}</span>
            </KbItemContainer>
          )}
          {totalExperience && (
            <KbItemContainer>
              <KbDot />
              <BootstrapTooltip title='Total Experience'>
                <img src={TotalExperience} alt='Total Experience' />
              </BootstrapTooltip>

              <span>{totalExperience}</span>
            </KbItemContainer>
          )}
          {manDaysOfEffort && (
            <KbItemContainer>
              <KbDot />
              <BootstrapTooltip title='Man Days of Effort'>
                <img src={Effort} alt='Man days of Efforts' />
              </BootstrapTooltip>

              <span>{manDaysOfEffort}</span>
            </KbItemContainer>
          )}
          {usedInProduct && (
            <KbItemContainer>
              <KbDot />
              <BootstrapTooltip title='Used in a product'>
                <img src={UsedInProduct} alt='Used in product' />
              </BootstrapTooltip>

              <span>Used in a Product</span>
            </KbItemContainer>
          )}
        </div>

        {readingTime && (
          <KbItemContainer>
            <BootstrapTooltip title='Time to read'>
              <img src={ReadingTime} alt='Read time' />
            </BootstrapTooltip>

            <span>{readingTime}</span>
          </KbItemContainer>
        )}
      </KbBanner>
    </>
  );
};
