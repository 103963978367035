import { FormControl, useTheme } from '@mui/material';

import { styled } from '@mui/material/styles';

import { alpha } from '@mui/material/styles';

import Menu, { MenuProps } from '@mui/material/Menu';

export const KbDropdown = styled(FormControl)(({ theme }) => ({
  minWidth: '11.25rem',
  maxWidth: '11.25rem',
  marginRight: '1rem',
  '&:last-child': {
    marginRight: 0,
  },
  '& .MuiInputLabel-root': {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
  },
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0.75rem 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const ButtonStyle = (btnActive: boolean) => {
  const theme = useTheme();
  return {
    marginLeft: '1rem',
    backgroundColor: theme.palette.background.paper,
    color: btnActive ? '#0B93DF' : theme.palette.text.secondary,
    border: `1px solid ${btnActive ? theme.palette.primary.main : 'transparent'}`,
    ':active': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
    ':hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
  };
};

export const cardStyle = {
  width: '29.688rem',
  padding: '0rem 1rem',
};

export const chipStyle = {
  marginLeft: '0.2rem',
  marginBottom: '0.5rem',
  fontSize: '0.75rem',
};
