import React from 'react';
import { RightSideHeader } from '../../Components/molecules/RightSideBar';

interface TeamInterface {
  tabName: string;
}

export const Team = ({ tabName }: TeamInterface) => {
  return (
    <>
      <RightSideHeader>{tabName}</RightSideHeader>
      <RightSideHeader></RightSideHeader>
    </>
  );
};
