import React from 'react';
import { styled } from '@mui/material/styles';
import Markdown from 'markdown-to-jsx';

const KbMarkdownComponent = styled(Markdown)(({ theme }) => ({
  '& ul, ul&': {
    lineHeight: 1.87,
    fontWeight: 400,
    marginBottom: '.5rem',
    marginTop: 0,
    paddingLeft: '1.8rem',
  },
  '& ol': {
    lineHeight: 1.87,
    fontWeight: 400,
    marginBottom: '.5rem',
    marginTop: 0,
    paddingLeft: '1.8rem',
  },
  '& p, p&': {
    marginTop: 0,
    marginBottom: '.5rem',
    lineHeight: 1.87,
    fontWeight: 400,
  },
  '& span, span&': {
    marginTop: 0,
    marginBottom: '.5rem',
    lineHeight: 1.87,
    fontWeight: 400,
  },
  '& h1': {
    marginBottom: '.5rem',
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: '1rem',
  },
  '& h2': {
    marginBottom: '.5rem',
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: '1rem',
  },
  '& h3': {
    marginBottom: '.5rem',
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: '1rem',
  },
  '& h4': {
    marginBottom: '.5rem',
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: '1rem',
  },
  '& h5': {
    marginBottom: '.5rem',
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: '1rem',
  },
  '& h6': {
    marginBottom: '.5rem',
    marginTop: 0,
    fontWeight: 500,
    lineHeight: 1.87,
    fontSize: '1rem',
  },
  '& img': {
    width: '100%',
  },
  '& code': {
    width: '100%',
    display: 'block',
    whiteSpace: 'break-spaces',
  },
  '& div ol': {
    paddingLeft: '1.8rem',
  },
}));

interface MarkdownInterface {
  children: string;
  className?: string;
}

interface ImageProps {
  alt: string;
  src: string;
}

export const MarkdownComponent = ({ children, className }: MarkdownInterface) => {
  const Image = (props: ImageProps) => {
    return <img src={props.src} alt={props.alt} />;
  };

  const options = {
    overrides: {
      img: {
        component: Image,
      },
    },
  };

  return (
    <KbMarkdownComponent className={className} options={options}>
      {children}
    </KbMarkdownComponent>
  );
};
