import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SearchBox } from '../../Components/atoms/SearchBox';
import { BlogListItem } from '../../Components/molecules/ListItem';
import { useSearchParams } from 'react-router-dom';
import { ISearch } from '../../types/Search';
import { CircularLoader } from '../../Components/atoms/CircularLoader';
import { axiosInstance } from '../../Utils/AxiosInstance';

const KbSearchPageContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 6.125rem)',
  paddingBottom: '2rem',
  overflow: 'auto',
  '& .searchInputContainer': {
    maxWidth: '48.25rem',
    margin: '1.875rem auto 2.625rem',
    '& .MuiInputBase-root': {
      background: '#ffffff',
    },
  },
  '& .SearchCountContainer': {
    paddingBottom: '1rem',
    marginBottom: '1rem',
    borderBottom: '0.0625rem solid #BEBEBE',
    '& .MuiTypography-body1': {
      fontSize: '1.25rem',
      lineHeight: '1.4375rem',
      '& .count': {
        fontWeight: '700',
      },
    },
  },
  '& .noDataText': {
    textAlign: 'center',
  },
}));

const SearchListing = () => {
  const [indexes, setIndexes] = useState<any[]>([]);
  const [searchResult, setSearchResult] = useState<ISearch[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const contentType = ['hard-problem', 'blogs-and-tl-articles', 'innovations'];
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';




  useEffect(() => {
    if(query.length){
      const fetchResult=async (query:string)=>{
        try{
          const response = await axiosInstance.get(`/tech-digests?filters[$or][0][name][$contains]=${query}&filters[$or][1][contents][Title][$contains]=${query}&populate=deep,3`);
          if(response?.status===200){
            setSearchResult(response?.data?.data)
          }
        }catch(err){
          console.log(err)
        }
      }
      fetchResult(query);
    }else{
      setSearchResult([])
    }
    
  }, [ query]);



  return (
    <KbSearchPageContainer>
      <Container>
        <Box className='searchInputContainer'>
          <SearchBox id='search' labelText='Search for Tech Digest' value={query} focus={true} />
        </Box>
        {loading ? (
          <CircularLoader />
        ) : searchResult && query ? (
          <>
            <Box className='SearchCountContainer'>
              <Typography variant='body1'>
                Search Results :{' '}
                <Box component='span' className='count'>
                  {searchResult?.length}
                </Box>
              </Typography>
            </Box>
            <Box>
              {searchResult?.map((item: ISearch) => {
                const { name, createdAt, icon, additional_info, tech_digests, content_type, slug, contributors,maturity_level,tech_categories, description } =
                  item?.attributes;
                const url = icon?.data?.attributes?.url;
                const impact = maturity_level?.data?.attributes?.name || '';
                const technology = tech_digests?.name;
                const articleType = content_type?.slug;
                const author = contributors?.username;
                const categorySlug = tech_categories?.data[0]?.attributes?.slug;
                const categoryName = tech_categories?.data[0]?.attributes?.name;
                const techDescription = description;
                return (
                  <BlogListItem
                    key={item?.id}
                    author={author}
                    title={name}
                    impact={impact}
                    date={createdAt}
                    image={url}
                    alt={name}
                    technology={technology}
                    tech_categories={tech_categories}
                    link={{ pathname: `/${categorySlug}/${name}`, state: {data:slug} }}
                    articleType={articleType}
                    imgWrapperWidth='15.625rem'
                    description={techDescription}
                  />
                );
              })}
            </Box>
          </>
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results {query ? `for : ${query}` : ''}
          </Typography>
        )}
      </Container>
    </KbSearchPageContainer>
  );
};

export default SearchListing;
