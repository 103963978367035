import { Typography } from '@mui/material';
import React from 'react';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import { RightSideContent, RightSideHeader } from '../../Components/molecules/RightSideBar';
interface BriefDetailsInterface {
  data: string;
  tabName?: string;
}

export const BriefDetails = ({ data, tabName }: BriefDetailsInterface) => {
  return (
    <>
      {/* <RightSideHeader>{tabName}</RightSideHeader> */}
      <RightSideContent>
        {data ? (
          <>
            <MarkdownComponent>{data}</MarkdownComponent>
          </>
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results
          </Typography>
        )}
      </RightSideContent>
    </>
  );
};
