import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface RightSideBarInterface {
  children?: React.ReactNode;
}

const KbRightSideBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: '2.5rem 0',
  borderRadius: '0.375rem',
  flex: '0 0 63.5%',
  height: '100%',
  flexDirection: 'column',
  overflow: 'hidden',
}));

export const RightSideBar = ({ children }: RightSideBarInterface) => {
  return <KbRightSideBar>{children}</KbRightSideBar>;
};

const KbRightSideHeader = styled(Typography)(({ theme }) => ({
  paddingInline: '2.5rem',
  marginBottom: '2rem',
  fontSize: '1.875rem',
  '&::first-letter': {
    textTransform: 'uppercase',
  },
}));

export const RightSideHeader = ({ children }: RightSideBarInterface) => {
  return <KbRightSideHeader variant='h2'>{children}</KbRightSideHeader>;
};

const KbRightSideContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 5rem)',
  // overflow: 'auto',
  boxSizing: 'border-box',
  // paddingInline: '2.5rem',
}));

export const RightSideContent = ({ children }: RightSideBarInterface) => {
  return <KbRightSideContent>{children}</KbRightSideContent>;
};
